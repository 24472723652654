import './App.css';

import androidImg from './assets/android.png';
import iphoneImg from './assets/iphone.png';

import feature1 from './assets/write-anywhere.png';
import feature2 from './assets/margin-notes.png';
import feature3 from './assets/write-beautiful.png';

import feature1Text from './assets/write-anywhere-text.png';
import feature2Text from './assets/margin-notes-text.png';
import feature3Text from './assets/write-beautiful-text.png';

import appStore from './assets/app-store.png';
import googlePlay from './assets/google-play.png';

function App() {
  return (
    <div className="app">
      <header>
        <div className="container">
          <h1>writing space</h1>
        </div>
      </header>
      <div className="main">
        <div className="container">
          <div className="main-content">
            <h2>
              Start writing right away with beautiful preset themes designed for
              various genres. Easily publish as a webpage or export as PDF.
            </h2>
            <div className="download-buttons">
              <img src={appStore} alt="download on app store" />
              <img src={googlePlay} alt="download on google play" />
            </div>
          </div>
          <div className="phones">
            <img
              className="android"
              src={androidImg}
              alt="screenshot of writing space running on an android phone"
            />
            <img
              className="iphone"
              src={iphoneImg}
              alt="screenshot of writing space running on an iphone"
            />
          </div>
        </div>
      </div>
      <div className="features">
        <div className="container">
          <ul className="features-list">
            <li>
              <img src={feature1} alt="write anywhere" />
              <div className="features-list__content">
                <img
                  className="features-list__title-img"
                  src={feature1Text}
                  alt="write anywhere"
                />
                <p>
                  Easily write, format, and edit right on your mobile device
                  anywhere. Start writing on your mobile and pick it up later on
                  your tablet*.
                </p>
                <small>*Tablet support is arriving soon.</small>
              </div>
            </li>
            <li>
              <img src={feature2} alt="margin notes" />
              <div className="features-list__content">
                <img
                  className="features-list__title-img"
                  src={feature2Text}
                  alt="write anywhere"
                />
                <p>
                  Easily create a margin note to jot down ideas or as a note to
                  come back to later.
                </p>
              </div>
            </li>
            <li>
              <img src={feature3} alt="write beautiful" />
              <div className="features-list__content">
                <img
                  className="features-list__title-img"
                  src={feature3Text}
                  alt="write anywhere"
                />
                <p>
                  Pick from one of our preset themes, or create your own from
                  scratch.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <footer></footer>
    </div>
  );
}

export default App;
